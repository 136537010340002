import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

import { transformResponse as transformResponseSubUsers } from '../../../../utils/transformResponse';
import { Tooltip } from '../../../../components';
import { DataListHocForDevicesTable } from '../../../DataList';
import TabContentContainer from '../../components/TabContentContainer';
import SubUsers from './SubUsers';
import { SENSORS_LIST_ID } from '../../../UserDetails/constants';
import { openModalWindow, DATA_EXPORT_SUB_METER_MODAL_ID } from '../../../ModalWindow/slice';
import { dataListID } from '../../../SubUsers/constants';
import { getSubUserData, dataListClean } from '../../../DataList/slice';
import { getSubSensorsFromSensors } from '../../selectors';
import { getDataListSensorsDataSelector } from '../../../../redux-store/selectors/dataList';
import { USERS_SUB_USER_SUB_METER_URL } from '../../../../api/apiUrls';
import { ADDITIONAL_COST_MODAL_ID, DataExportModalWindow } from '../../../../modals';
import { PrimaryButton, ZohoHoc } from '../../../../components/UIKit';
import { sendSubMeterData } from './actionCreator';
import CarChargers from './CarChargers';
import columns from '../../columns';
import { READ_ONLY_ROLES } from '../../../../constants';

import i18n from '../../../../i18n';
import './index.scss';

const ZEV_ZOHO_LINK = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/wie-bilde-ich-ein-zev-mit-solar-manager-ab';
const dataExportZohoLink = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/wie-exportiere-ich-meine-daten-als-csv';

const NoDataComponentRender = () => (
  <div
    className="rt-noData customNoData"
    style={{
      top: '50%'
    }}
  >
    {i18n.t('noRowsFound')}
  </div>
);

/**
 * @module DevicesTable
 */
/**
 * Smart Meter tab from DevicesTable
 * @memberof module:DevicesTable
 */
const SmartMeterTab = (props) => {
  const {
    transformResponse,
    openAddDevices,
    defaultParams,
    subMetersUrl,
    myRoleType,
    devicesUrl,
    userId,
    total,
    widthSetting,
    columnsSetting,
    isReadOnlyPermission
  } = props;
  const dispatch = useDispatch();

  const dataSensors = useSelector(getDataListSensorsDataSelector);
  const subSensors = useSelector(getSubSensorsFromSensors);

  const isExistSmartMeter = !!dataSensors.length && dataSensors[0].device_type === 'smart-meter';

  const ref = useRef(subSensors?.length);

  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);

  const columnsSmartMeter = useMemo(
    () => columns(myRoleType, userId, 'smart-meter', total, isReadOnlyPermission, widthSetting, columnsSetting),
    [myRoleType, total, userId, isReadOnlyPermission,
      widthSetting, columnsSetting]
  );

  const columnsSubMeters = useMemo(
    () => columns(myRoleType, userId, 'sub-meter', total, isReadOnlyPermission, widthSetting, columnsSetting),
    [myRoleType, total, userId, isReadOnlyPermission,
      widthSetting, columnsSetting]
  );

  const [subTableColumns, setSubTableColumns] = useState([...columnsSubMeters]);
  const [expanded, setExpanded] = useState({});
  const onResizedChange = useCallback(
    (newResized) => {
      const columnsWidth = subTableColumns.map((column) => {
        const currentColumn = newResized.find((resized) => resized.id === column.id);

        if (currentColumn) {
          return {
            ...column,
            minWidth: currentColumn.value,
            width: currentColumn.value
          };
        }
        return column;
      });
      setSubTableColumns(columnsWidth);
    },
    [subTableColumns]
  );

  const onExpandedChangeHandler = (data, index) => {
    const [data1] = index;
    setExpanded((prev) => ({
      ...prev,
      ...{ [index]: !expanded[index] }
    }));
    if (!data[data1]) return;
    const parentIdOfSubUsers = subSensors[data1].id || subSensors[data1]._id;

    dispatch(getSubUserData(
      {
        listID: dataListID,
        dataList: {
          listURL: `${USERS_SUB_USER_SUB_METER_URL}/${parentIdOfSubUsers}`,
          params: defaultParams,
          transformResponse: transformResponseSubUsers,
          parentIdOfSubUsers
        }
      }
    ));
  };

  const exportDataHandler = () => dispatch(openModalWindow(
    { modalID: DATA_EXPORT_SUB_METER_MODAL_ID }
  ));

  const handleOpenAddSubMeter = () => {
    dispatch(openModalWindow({ modalID: ADDITIONAL_COST_MODAL_ID, data: { translationKey: 'additionalCostInfo' } }));
    openAddDevices('sub-meter');
  };

  const SubComponentHandler = useCallback((v) => (
    <>
      <SubUsers
        transformResponse={transformResponse}
        defaultParams={defaultParams}
        myRoleType={myRoleType}
        rowData={v}
        isReadOnlyPermission={isReadOnlyPermission}
      />
      <CarChargers
        myRoleType={myRoleType}
        rowData={v}
        isReadOnlyPermission={isReadOnlyPermission}
      />
    </>
  ), [defaultParams, myRoleType, transformResponse, isReadOnlyPermission]);

  useEffect(() => {
    if (ref?.current !== subSensors?.length) {
      setExpanded({});
    }
  }, [subSensors?.length]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(dataListClean(dataListID));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubTableColumns([...columnsSubMeters]);
  }, [columnsSubMeters]);

  return (
    <>
      {!(isReadOnlyRole || isReadOnlyPermission) && !isExistSmartMeter && (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={openAddDevices.bind(null, 'smart-meter')}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addSmartMeterBtn')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded smart-meters-data-list">
        <DataListHocForDevicesTable
          tab="smart-meter"
          listID={SENSORS_LIST_ID}
          listURL={devicesUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          Component={ReactTable}
          componentProps={{
            onResizedChange,
            defaultPageSize: 100,
            className: '-highlight m-datatable__table',
            columns: columnsSmartMeter,
            showPagination: false,
            sorted: []
          }}
        />
      </div>
      {isExistSmartMeter && (
        <>
          <DataExportModalWindow
            initialValues={{
              fromDate: moment().startOf('month').startOf('day').valueOf(),
              toDate: moment().valueOf()
            }}
            onSubmit={(fields) => dispatch(sendSubMeterData(fields))}
            modalID={DATA_EXPORT_SUB_METER_MODAL_ID}
            modalTitle="meterDataExport"
          />
          <Tabs onSelect={() => {}} selectedTabClassName="sub-meter" className="sub-meter">
            <TabList className="d-flex justify-content-between">
              <Tab disabled>
                <TabContentContainer className="sub-meter-container">
                  {i18n.t('Sub Meters')}
                  <Tooltip
                    title={(
                      <>
                        {i18n.t('subMeterTooltip')}
                        <br />
                        <br />
                        {i18n.t('furtherInformation')}
                        :
                        <br />
                        <a className="zev-zoho-link" href={ZEV_ZOHO_LINK} target="_blank" rel="noopener noreferrer">
                          {ZEV_ZOHO_LINK}
                        </a>
                      </>
                    )}
                    placement="right"
                    style={{
                      color: 'var(--primary-color)'
                    }}
                  />
                </TabContentContainer>
              </Tab>
              {Boolean(subSensors?.length) && (
                <Tab disabled>
                  <div className="container-fluid">
                    <div className="d-flex justify-content-end order-3 btn-sm-us export-button">
                      <ZohoHoc zohoLink={dataExportZohoLink}>
                        <PrimaryButton onClickHandler={exportDataHandler} i18nLabel="meterDataExport" isLargeButton type="upload" zohoLink={dataExportZohoLink} />
                      </ZohoHoc>
                    </div>
                  </div>
                </Tab>
              )}
            </TabList>
            {/* fix warning: There should be an equal number of 'Tab' and 'TabPanel' in `UncontrolledTabs` */}
            <TabPanel />
            {Boolean(subSensors?.length) && (<TabPanel />)}
          </Tabs>
          {!(isReadOnlyRole || isReadOnlyPermission) && (
            <div className="devices-tab-panel col-auto">
              <a
                onClick={handleOpenAddSubMeter}
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
              >
                <span>{i18n.t('addSubMetersBtn')}</span>
              </a>
            </div>
          )}
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded sub-meter-data-list">
            <DataListHocForDevicesTable
              tab="sub-meter"
              listID="sub-meter"
              listURL={subMetersUrl}
              params={defaultParams}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                onExpandedChange: onExpandedChangeHandler,
                NoDataComponent: NoDataComponentRender,
                SubComponent: SubComponentHandler,
                className: '-highlight m-datatable__table',
                freezeWhenExpanded: true,
                columns: subTableColumns,
                showPagination: false,
                defaultPageSize: 100,
                expanded,
                sorted: []
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

SmartMeterTab.propTypes = {
  myRoleType: PropTypes.string,
  devicesUrl: PropTypes.string,
  subMetersUrl: PropTypes.string.isRequired,
  userId: PropTypes.string,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  widthSetting: PropTypes.instanceOf(Object),
  openAddDevices: PropTypes.func,
  transformResponse: PropTypes.func.isRequired,
  columnsSetting: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default SmartMeterTab;
