import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Typography } from 'antd';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import { columnsEndUser } from './columns';
import { UserInfoSub } from '../../components/ReactTable/renderFunctions';
import PropMgmtInfo from './components/PropMgmtInfo';
import DataList, { DataListPagination, DataListV2, DataListFilterV2 } from '../DataList';
import {
  openModalWindow,
  modalWindowSend,
  ADD_END_USERS_TO_PROP_MGMT_MODAL_ID,
  REMOVE_DATA_MODAL_ID
} from '../ModalWindow/slice';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/slice';
import { AddEndUserToSupportWindow } from './modals';
import { DeleteEndUserModalWindow, ConfirmDeleteUserWindow } from '../../modals';

import { SearchField, TopBar, Pagination } from '../../components';
import { dataListIDEndUsers } from './constants';
import { getSignInUserOemVisibleWebSelector } from '../../redux-store/selectors/signIn';
import { getDataListCurrentPropMgmtSelector } from '../../redux-store/selectors/dataList';
import {
  USERS_SOLAR_MANAGERS_USERS_URL,
  USERS_GET_PROP_MGMT_END_USERS_URL
} from '../../api/apiUrls';

const transformResponse = (data) => ({ total: data.total, data: data.list });

const PropMgmtDetails = (props) => {
  const [expanded, setExpanded] = useState({});

  const columns = columnsEndUser(props.myRoleType);
  const { [dataListIDEndUsers]: pageSettings } = useSelector(getPagesSettingsSelector);
  const { currentPropMgmt } = useSelector((state) => state.dataList);
  const {
    openModalWindow: openModal,
    modalWindowSend: modalSend,
    visibleWeb,
    propMgmtDataList,
    match
  } = props;

  const { propMgmtID } = match.params;
  const propMgmtDataListURL = `/users/property-management/${propMgmtID}`;
  const connectedEndUsersUrl = `${USERS_GET_PROP_MGMT_END_USERS_URL}?propertyManagementUserId=${propMgmtID}`;
  const propMgmtHasLoaded = !!propMgmtDataList.data?._id;

  const sendAddEndUsers = (fields) => {
    const filteredData = {
      ...fields,
      pv_installer: undefined
    };

    modalSend(
      {
        modalID: ADD_END_USERS_TO_PROP_MGMT_MODAL_ID,
        requestConfig: {
          method: 'post',
          url: USERS_SOLAR_MANAGERS_USERS_URL,
          data: {
            ...filteredData,
            propertyManagementEmail: currentPropMgmt?.data?.email
          }
        },
        cb: () => dataListReloadData({ listID: dataListIDEndUsers })
      });
  };

  const sendRemoveUser = ({ id }) => modalSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListIDEndUsers })
  });

  return (
    <>
      <TopBar visibleWeb={visibleWeb} showNavigation />
      <DataList
        listID="currentPropMgmt"
        listURL={propMgmtDataListURL}
        Component={PropMgmtInfo}
        params={{}}
      />

      {propMgmtHasLoaded && (
        <div className="container-fluid">
          <div
            className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10"
            style={{ margin: '30px 0 10px' }}
          >
            <DataListFilterV2
              Component={SearchField}
              componentProps={{
                placeholder: i18n.t('searchAllTablesElements'),
                defaultValue: pageSettings.queries.search,
                searchByFields: 'sm_id'
              }}
              listURL={connectedEndUsersUrl}
              listID={dataListIDEndUsers}
              transformResponse={transformResponse}
            />
          </div>
          <div className="d-flex justify-content-between m-4 align-items-center">
            <Typography
              variant="h5"
              className="table-details-user-nifo"
              style={{ textTransform: 'uppercase', fontWeight: 700, fontSize: '18px' }}
            >
              {i18n.t('SMUs')}
            </Typography>
            <div className="">
              <a
                className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                onClick={openModal.bind(null, { modalID: ADD_END_USERS_TO_PROP_MGMT_MODAL_ID, data: null })}
              >
                <span>
                  <span>{i18n.t('addSMUbtn')}</span>
                </span>
              </a>
            </div>
          </div>

          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <AddEndUserToSupportWindow onSubmit={sendAddEndUsers} propertyManagementUserId={propMgmtID} />
            <DeleteEndUserModalWindow onSubmit={sendRemoveUser} />
            <ConfirmDeleteUserWindow userRole={i18n.t('eUser').toLowerCase()} />

            <DataListV2
              listID={dataListIDEndUsers}
              listURL={connectedEndUsersUrl}
              params={pageSettings.queries}
              transformResponse={transformResponse}
              Component={ReactTable}
              componentProps={{
                defaultPageSize: 0,
                className: '-highlight m-datatable__table',
                columns,
                manual: true,
                SubComponent: UserInfoSub,
                expanded,
                onExpandedChange: (all) => setExpanded(all)
              }}
            />
          </div>
          <DataListPagination
            Component={Pagination}
            listID={dataListIDEndUsers}
            defaultPageSize={10}
          />
        </div>
      )}
    </>
  );
};

PropMgmtDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  propMgmtDataList: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  visibleWeb: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  propMgmtDataList: getDataListCurrentPropMgmtSelector,
  visibleWeb: getSignInUserOemVisibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  modalWindowSend
})(PropMgmtDetails);
