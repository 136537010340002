import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import PropTypes from 'prop-types';

// eslint-disable-next-line arrow-body-style
const Preloader = ({ size = 70, height }) => {
  return (
    <div className="mainPreLoaderParent" style={{ height }}>
      <div className="mainPreLoader">
        <PuffLoader size={size} color="#d52329" speedMultiplier={3} />
      </div>
    </div>
  );
};

Preloader.propTypes = {
  size: PropTypes.number,
  height: PropTypes.number
};

export default Preloader;
