/* eslint-disable import/no-named-as-default-member */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment-timezone';

import { columns, columnsEmp, columnsOEM } from './columns';
import { UserInfoSub, CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import CompanyInfo from './components/CompanyInfo';
import DataList, { DataListPagination, DataListFilter } from '../DataList';
import { openModalWindow, modalWindowSend, ADD_USER_MODAL_ID, REMOVE_DATA_MODAL_ID, ADD_EMPLOYEE_MODAL_ID } from '../ModalWindow/slice';
import { dataListReloadData } from '../DataList/slice';
import { AddUserForEmployeeWindow, DeleteCompanyEmployeeWindow } from './modals';
import { SearchField, TopBar, Pagination } from '../../components';
import { configSavedSettings } from '../../utils';
import { companyDataListID, getOEMsDefaultParams } from './constants';
import { EMPLOYEE_ROLE_TYPES } from '../UserDetails/constants';
import { getSignInUserOemVisibleWebSelector, getSignInUser_IdSelector } from '../../redux-store/selectors/signIn';
import { companyDataListSelector } from '../../redux-store/selectors/dataList';
import { OEM_CONNECTED_END_USERS_URL, OEM_CONNECTED_OEMS_URL, USERS_EMPLOYEE_URL, USERS_GET_PROP_MGMT_END_USERS_URL, USERS_PV_INSTALLER_USERS_URL, USERS_SOLAR_MANAGERS_USERS_URL, USERS_URL } from '../../api/apiUrls';
import { DeleteEndUserModalWindow, ConfirmDeleteUserWindow, AddEmployeeModalWindow } from '../../modals';

import i18n from '../../i18n';
import { transformResponse } from '../../utils/transformResponse';

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    const {
      myRoleType,
      history
    } = this.props;
    const { location } = history || {};
    const { pathname: pathName, key: historyKey } = location || {};
    this.columns = columns(myRoleType);
    this.columnsEmp = columnsEmp(myRoleType);
    this.columnsOEM = columnsOEM(myRoleType);

    configSavedSettings(
      this,
      'company-details',
      {
        expander: true,
        subTabs: true,
        defaultParams: {
          _limit: Number(localStorage.getItem('DataListPaginationPageSize')) || 10,
          _sort: 'first_name:asc',
          _start: 0
        },
        pathName,
        historyKey
      }
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ expanded: {} });
  }

  componentDidMount() {
    moment.tz.setDefault();
  }

  render() {
    const { expanded, activeTab } = this.state;
    const {
      myRoleType,
      companyDataList,
      match,
      oemId,
      openModalWindow: openModal,
      modalWindowSend: modalSend,
      visibleWeb
    } = this.props;

    const getUsersUrl = {
      property_management: USERS_GET_PROP_MGMT_END_USERS_URL,
      oem: `${OEM_CONNECTED_END_USERS_URL}/${oemId}/${companyDataList?.data?.my_company?._id}`,
      default: USERS_URL
    };

    const companyIdField = {
      property_management: { pvInstallerId: companyDataList?.data?.my_company?._id },
      default: { company: companyDataList?.data?.my_company?._id }
    };

    const getOEMsUrl = {
      default: `${OEM_CONNECTED_OEMS_URL}/${companyDataList?.data?._id}`
    };

    const getOEMsParams = {
      property_management: {
        ...getOEMsDefaultParams,
        pvInstallerId: companyDataList?.data?.my_company?._id
      },
      default: getOEMsDefaultParams
    };

    const { companyID } = match.params;
    const companyDataListURL = `${USERS_PV_INSTALLER_USERS_URL}/${companyID}`;
    const companyHasLoaded = !!companyDataList.data?.my_company;
    const dataListIDUsers = 'company_sm_users';
    const listURLUsers = getUsersUrl[myRoleType] || getUsersUrl.default;
    const listURLEmployee = USERS_EMPLOYEE_URL;
    const listOEM = getOEMsUrl[myRoleType] || getOEMsUrl.default;
    const pvInstallersEndUsersParams = {
      ...this.defaultParams,
      ...(companyIdField[myRoleType] || companyIdField.default)
    };
    const paramsOEM = getOEMsParams[myRoleType] || getOEMsParams.default;

    const subTabs = ['end-users', 'employees', 'oem'];
    const allowedToAddEndUser = ['root', 'solar_admin', 'property_management', 'pv_installer', ...EMPLOYEE_ROLE_TYPES].includes(myRoleType);
    const allowedToAddEmployee = ['root', 'solar_admin', 'property_management', 'pv_installer'].includes(myRoleType);

    const sendAddEmployee = (fields) => modalSend({
      modalID: ADD_EMPLOYEE_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: USERS_EMPLOYEE_URL,
        data: fields
      },
      cb: () => dataListReloadData({ listID: dataListIDUsers })
    });

    const sendAddUser = (fields) => modalSend({
      modalID: ADD_USER_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: USERS_SOLAR_MANAGERS_USERS_URL,
        data: fields
      },
      cb: () => dataListReloadData({ listID: dataListIDUsers })
    });

    const sendRemoveUser = ({ id }) => modalSend({
      modalID: REMOVE_DATA_MODAL_ID,
      requestConfig: {
        method: 'delete',
        url: `/user/${id}`
      },
      cb: () => dataListReloadData({ listID: dataListIDUsers })
    });

    let activeTabIndex = subTabs.indexOf(activeTab);
    if (activeTabIndex === -1) activeTabIndex = 0;

    return (
      <>
        <TopBar visibleWeb={visibleWeb} showNavigation />
        <DataList
          listID={companyDataListID}
          listURL={companyDataListURL}
          Component={CompanyInfo}
        />
        {companyHasLoaded && (
          <div className="container-fluid">
            <div className="d-flex">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10">
                <DataListFilter
                  Component={SearchField}
                  componentProps={{
                    searchByFields: 'sm_id,email,first_name,last_name,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListIDUsers}
                />
              </div>
            </div>

            <Tabs selectedIndex={activeTabIndex} onSelect={(index) => this.changeSubTab(subTabs[index])}>

              <TabList>
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div
                          className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                        >
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('SMUs')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div
                          className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                        >
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('employees')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {!['oem'].includes(myRoleType) && (
                  <Tab>
                    <div className="row tabs-cont align-items-center">
                      <div className="col-auto mr-auto">
                        <div className="m-portlet__head-tools">
                          <div
                            className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                          >
                            <div className="nav-item m-tabs__item">
                              <div className="nav-link m-tabs__link">{i18n.t('OEM')}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
              </TabList>

              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  {allowedToAddEndUser && (
                    <div className="pvInstallerAddBtn">
                      <a
                        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                        onClick={openModal.bind(null, { modalID: ADD_USER_MODAL_ID, data: null })}
                      >
                        <span>
                          <span>{i18n.t('addSMUbtn')}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  <DeleteEndUserModalWindow onSubmit={sendRemoveUser} />
                  <ConfirmDeleteUserWindow userRole={i18n.t('eUser').toLowerCase()} />
                  <AddUserForEmployeeWindow
                    onSubmit={sendAddUser}
                    initialValues={{ pv_installer: companyDataList.data.my_company._id }}
                  />
                  <DataList
                    listID={dataListIDUsers}
                    listURL={listURLUsers}
                    params={pvInstallersEndUsersParams}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columns,
                      SubComponent: UserInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  {allowedToAddEmployee && (
                    <div className="pvInstallerAddBtn">
                      <a
                        className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                        onClick={openModal.bind(null, { modalID: ADD_EMPLOYEE_MODAL_ID, data: { roleName: 'pv_installer_employee' } })}
                      >
                        <span>
                          <span>{i18n.t('addEmployeebtn')}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  <DeleteCompanyEmployeeWindow
                    onSubmit={sendRemoveUser}
                  />
                  <AddEmployeeModalWindow
                    onSubmit={sendAddEmployee}
                    initialValues={{ pv_installer: companyDataList.data.my_company._id, role_type: 'pv_installer_employee' }}
                  />
                  <ConfirmDeleteUserWindow userRole={i18n.t('employee').toLowerCase()} />
                  <DataList
                    listID={dataListIDUsers}
                    listURL={listURLEmployee}
                    params={{ ...this.defaultParams, company: companyDataList.data.my_company._id }}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columnsEmp,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  <DataList
                    listID={dataListIDUsers}
                    listURL={listOEM}
                    params={paramsOEM}
                    transformResponse={transformResponse}
                    Component={ReactTable}
                    componentProps={{
                      defaultPageSize: 0,
                      className: '-highlight m-datatable__table',
                      columns: this.columnsOEM,
                      SubComponent: CompanyInfoSub,
                      manual: true,
                      onExpandedChange: (all) => {
                        this.saveExpanderChanges(all);
                      },
                      expanded
                    }}
                  />
                </div>
              </TabPanel>
            </Tabs>
            <DataListPagination
              Component={Pagination}
              listID={dataListIDUsers}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        )}
      </>
    );
  }
}

CompanyDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  companyDataList: PropTypes.instanceOf(Object).isRequired,
  visibleWeb: PropTypes.bool,
  oemId: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = createStructuredSelector({
  companyDataList: companyDataListSelector,
  oemId: getSignInUser_IdSelector,
  visibleWeb: getSignInUserOemVisibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  modalWindowSend
})(CompanyDetails);
