import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ViewByEndUsers from './components/ViewByEndUsers';
import ViewByAdmins from './components/ViewByAdmins';
import {
  getIsSupportContractFlagAllowedToBeChanged,
  fetchLastSensorsData,
  clearUserData,
  getUserInfo
} from './slice';
import { settingPrGetUser } from '../SettingProfile/slice';
import { getSettingProfileInitialDataUserSelector } from '../../redux-store/selectors/settingProfile';
import { getUserDevicesUserSelector } from '../../redux-store/selectors/userDevices';
import { useOauthFlowCallback } from '../../hooks';
import { editTariffId } from './constants';

import './index.scss';

/**
 * @module UserDetails
 */
/**
 * User details page
 * @memberof module:UserDetails
 */
const UserDetails = (props) => {
  const { myRoleType, match, location, history } = props;
  const dispatch = useDispatch();

  useOauthFlowCallback(location?.search, editTariffId, history);

  const user = useSelector(myRoleType === 'end_user' ? getSettingProfileInitialDataUserSelector : getUserDevicesUserSelector);
  const { userID } = match?.params || {};

  useEffect(() => {
    if (myRoleType !== 'end_user') {
      dispatch(getUserInfo({ userId: userID }));
      dispatch(getIsSupportContractFlagAllowedToBeChanged({ userId: userID }));
    } else {
      dispatch(settingPrGetUser({ userID: undefined, myself: true, roleLink: 'solar-managers-users' }));
    }
    return () => {
      dispatch(clearUserData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myRoleType !== 'end_user') {
      if (user?.gateway?._id) {
        dispatch(fetchLastSensorsData({ myRoleType, gatewayId: user?.gateway?._id }));
      }
    } else {
      dispatch(fetchLastSensorsData({ myRoleType }));
    }
  }, [dispatch, myRoleType, user?.gateway?._id]);

  return (
    myRoleType === 'end_user' ? (
      <ViewByEndUsers user={user} myRoleType={myRoleType} />
    ) : (
      <ViewByAdmins user={user} myRoleType={myRoleType} userID={userID} />
    )
  );
};

UserDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

export default UserDetails;
