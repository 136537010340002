import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import columns from './columns';
import { DeleteEmployeeWindow } from './modals';
import { openModalWindow, modalWindowSend, REMOVE_DATA_MODAL_ID, ADD_EMPLOYEE_MODAL_ID } from '../ModalWindow/slice';
import { DataListPagination, DataListV2, DataListFilterV2 } from '../DataList';
import { dataListReloadData } from '../DataList/slice';
import { SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';
import { getSignInUserMyCompanyVisibleWebSelector } from '../../redux-store/selectors/signIn';
import { USERS_EMPLOYEE_URL } from '../../api/apiUrls';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import i18n from '../../i18n';
import { AddEmployeeModalWindow, ConfirmDeleteUserWindow } from '../../modals';
import { transformResponse } from '../../utils/transformResponse';

/**
 * @module Employee
 */
const dataListID = 'employee';
const listURL = USERS_EMPLOYEE_URL;

/**
 * Renders employee page
 * @memberof module:Employee
 */
const Employees = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const visibleWeb = useSelector(getSignInUserMyCompanyVisibleWebSelector);
  const pagesSettings = useSelector(getPagesSettingsSelector);

  const { [dataListID]: pageSetting } = pagesSettings;
  const defaultParams = pageSetting.queries;

  const sendAddUser = (fields) => dispatch(modalWindowSend({
    modalID: ADD_EMPLOYEE_MODAL_ID,
    requestConfig: {
      method: 'post',
      url: USERS_EMPLOYEE_URL,
      data: fields
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }));

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListID })
  }));

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_EMPLOYEE_MODAL_ID, data: { roleName: 'pv_installer_employee' } }));

  return (
    <div className="main-table-container">
      <AddEmployeeModalWindow initialValues={{ role_type: 'pv_installer_employee' }} onSubmit={sendAddUser} />
      <DeleteEmployeeWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('employee').toLowerCase()} />

      <TopBar updateButton showNavigation visibleWeb={visibleWeb} />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('employees') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10 order-1 mr-auto">
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSetting.queries.search,
                    searchByFields: 'email,first_name,last_name,city',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={dataListID}
                  listURL={listURL}
                  transformResponse={transformResponse}
                />
              </div>
              <div className="col-auto order-2 btn-sm-us">
                <a
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                  onClick={onClickHandler}
                >
                  <span>
                    <span>{i18n.t('addEmployeebtn')}</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <DataListV2
            listID={dataListID}
            listURL={listURL}
            params={defaultParams}
            transformResponse={transformResponse}
            Component={ReactTable}
            componentProps={{
              defaultPageSize: 0,
              className: '-highlight m-datatable__table',
              columns: columns(myRoleType, pageSetting),
              manual: true
            }}
          />
          <DataListPagination
            Component={Pagination}
            listID={dataListID}
            defaultPageSize={10}
          />
        </div>
      </div>
    </div>
  );
};

Employees.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default Employees;
