import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { passwordAdvanced, equals, required } from '../validation';
import { LanguageSelector } from '../../components/UIKit';
import { Logo } from '../../components';
import { CustomTextInput } from '../../components/ReduxFormFields';

import i18n from '../../i18n';

import '../../App.css';

const validateConfirmPass = equals('password');
/**
 * ResetPassForm
 * @memberof module:ResetPass
 * @description renders Reset Password form
 * @extends Component
 */
export const ResetPassForm = (props) => {
  useEffect(() => {
    const { initialize, initialValues } = props;
    initialize(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit } = props;

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row justify-content-center align-items-center">
            <div className="mr-auto col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-6 col-lg-8 col-md-10 col-sm-10">
                <div className="m-login__head">
                  <h3 className="m-login__title">{i18n.t('resetPass')}</h3>
                  <div className="m-login__desc">{i18n.t('resetPassInputs')}</div>
                </div>
                <form className="m-login__form m-form">
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="password"
                      component={CustomTextInput}
                      label={i18n.t('reqNewPass')}
                      className="m-input"
                      type="password"
                      autoComplete="off"
                      preventAuto
                      validate={[required, passwordAdvanced]}
                      languageToSync={i18n.language}
                    />
                  </div>
                  <div className="form-group m-form__group input-field">
                    <Field
                      name="passwordConfirmation"
                      component={CustomTextInput}
                      label={i18n.t('repReqNewPass')}
                      type="password"
                      className="m-input"
                      autoComplete="off"
                      preventAuto
                      validate={[required, passwordAdvanced, validateConfirmPass]}
                      languageToSync={i18n.language}
                    />
                  </div>

                  <div className="m-login__form-action">
                    <button
                      type="button"
                      id="m_login_forget_password_submit"
                      className="btn m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn forgot-button m-login__btn--primary"
                      onClick={handleSubmit}
                    >
                      {i18n.t('submitBtn')}
                    </button>
                    &nbsp;&nbsp;
                    <Link
                      to="/login"
                      id="m_login_forget_password_cancel"
                      className="btn m-btn m-btn--pill m-btn--custom m-btn--air forgot-button m-login__btn"
                    >
                      {i18n.t('cancel')}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired
};

export default reduxForm({
  form: 'resetPasswordForm'
})(ResetPassForm);
