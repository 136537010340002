import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeManagerID,

  // R
  reqSMID: view.reqManagerID,

  // S
  SMID: view.ManagerID,
  SMIDTolltip: view.ManagerIDTolltip
};

export default replace;
